import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { decOfNums } from 'src/shared/utils'
import { HBox, Wrapper } from 'src/ui/layout'

import { Body, Header } from 'src/ui/typography'

import { useTheme } from 'src/ui/theme'
import { Props } from './types'

type TRegion = {
  name: string
  count: number
}

export const JobList = ({ byDepartmentsList, hotList }: Props) => {
  const theme = useTheme()

  const getVacancyCountByRegions = (regions: TRegion[]) =>
    regions.reduce((acc, cur) => acc + cur.count, 0)

  return (
    <Wrapper>
      <Header size="h4" as="h2">
        Команды KODE
      </Header>
      <HBox />
      <Body>
        Отделы компании чётко структурированы. В&nbsp;каждом работают тимлид,
        техлид, ведущие специалисты, начинающие сотрудники
        и&nbsp;их&nbsp;менторы. Отделы постоянно сотрудничают
        друг&nbsp;с&nbsp;другом, обмениваются&nbsp;информацией, идеями
        и&nbsp;опытом их&nbsp;воплощения.
      </Body>
      <HBox height={24} />
      {Boolean(byDepartmentsList) && (
        <>
          {byDepartmentsList.map((department, index) => {
            const activeVacancies = getVacancyCountByRegions(department.regions)

            return (
              <div key={index}>
                <Header size="h4" as="h3">
                  {department.departmentName}
                </Header>
                <HBox height={8} />
                <Body size="secondary">{department.description}</Body>
                <HBox height={8} />
                <Body size="secondary">
                  <Link
                    to="/career/"
                    state={{
                      department: {
                        label: department.departmentName,
                        value: department.departmentId,
                      },
                    }}
                  >
                    {`${activeVacancies} ${decOfNums.formatVacancy(
                      activeVacancies
                    )}`}
                  </Link>
                </Body>
                {index < byDepartmentsList.length - 1 ? (
                  <HBox height={24} />
                ) : null}
              </div>
            )
          })}
        </>
      )}
    </Wrapper>
  )
}
