import React from 'react'

import Img from 'gatsby-image'
import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { openModal, modalNames } from 'src/shared/features/modal'
import { useSliderSwipe } from 'src/shared/hooks'
import { sliderInMainSettings } from 'src/shared/constants'

import { HBox, Wrapper } from 'src/ui/layout'
import { CustomSliderContainer } from 'src/ui/atoms'
import { Button } from 'src/ui/molecules'
import { SwiperSlider } from 'src/ui/organisms'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'

import type { TSliderItem, TSliderText } from './types'

const Container = styled(CustomSliderContainer)`
  position: relative;
  height: 780px;

  .swiper-pagination {
    width: initial;
    left: 50%;
  }
`

const SliderItem = styled.div`
  height: 780px;
`

const Image = styled(Img)`
  display: block;
  position: absolute !important;
  object-fit: cover;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  z-index: 3;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 7.6%,
    rgba(0, 0, 0, 0.1) 100%
  );
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 64px;
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledBody = styled(Body)`
  max-width: 416px;
  white-space: pre-line;
`

const Title = styled.span`
  white-space: pre-line;
`

type Props = {
  sliderItems: TSliderItem[]
  sliderText: TSliderText
}

export const HeroBlock = ({ sliderItems, sliderText }: Props) => {
  const theme = useTheme()
  const { sliderRef, handleMouseDown, handleMouseUp } = useSliderSwipe()

  const { label, title, text } = sliderText

  return (
    <Container>
      <SwiperSlider
        ref={sliderRef}
        settings={sliderInMainSettings}
        customPagination
      >
        {sliderItems.map(({ image }, index) => {
          if (image) {
            return (
              <SwiperSlide key={index}>
                <SliderItem>
                  <Wrapper>
                    <Image fluid={image} />
                  </Wrapper>
                </SliderItem>
              </SwiperSlide>
            )
          }
        })}
      </SwiperSlider>
      <ContentWrapper
        tabIndex={0}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
      >
        <Content>
          {label ? (
            <>
              <Label color={theme.colors.white.primary} weight="bold">
                {label}
              </Label>
              <HBox height={8} />
            </>
          ) : null}

          <InfoBlock>
            <Header size="h1" as="h1" color={theme.colors.white.primary}>
              <Title>{title}</Title>
            </Header>

            <HBox height={24} />
            <StyledBody color={theme.colors.white.primary}>{text}</StyledBody>
            <HBox height={48} />
            <HBox height={48} />
            {/* <Button onClick={() => openModal(modalNames.contactUs)}>
              Связаться с нами
            </Button> */}
          </InfoBlock>
        </Content>

        <div className="swiper-pagination"></div>
      </ContentWrapper>
    </Container>
  )
}
