import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Peoples as Desc } from './desk'
import { Peoples as Mob } from './mob'

import { Props } from './types'

export const Peoples: React.FC<Props> = ({ data }) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob data={data} /> : <Desc data={data} />
}
