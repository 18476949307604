import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { HeroBlock as Desc } from './desk'
import { HeroBlock as Mob } from './mob'
import { TSliderItem, TSliderText } from './types'

type Props = {
  sliderItems: TSliderItem[]
  sliderText: TSliderText
  images: TImages
}
export const HeroBlock: React.FC<Props> = ({ images, sliderItems, sliderText }) => {
  const { isMobile } = useDimensions()
  return isMobile
    ?
    <Mob images={images} sliderItems={sliderItems} sliderText={sliderText} />
    :
    <Desc sliderItems={sliderItems} sliderText={sliderText} />
}
