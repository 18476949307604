import { MouseEvent, useCallback, useRef, useState } from 'react'
import { Swiper } from 'swiper'

export const useSliderSwipe = () => {
  const sliderRef = useRef<Swiper>(null);
  const [touchStart, setTouchStart] = useState(0);

  const handleMouseDown = useCallback((e: MouseEvent) => {
    setTouchStart(() => e.clientX)
  }, [])

  const handleMouseUp = useCallback((e: MouseEvent) => {
    if (touchStart - e.clientX < 0) {
      if (sliderRef?.current?.slidePrev) {
        sliderRef.current.slidePrev()
      }
    }

    if (touchStart - e.clientX > 0) {
      if (sliderRef?.current?.slideNext) {
        sliderRef.current.slideNext()
      }
    }
  }, [touchStart, sliderRef])

  return {
    sliderRef,
    handleMouseDown,
    handleMouseUp
  }
}