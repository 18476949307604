import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Button } from 'src/ui/molecules'
import { Body } from 'src/ui/typography'

import { openModal, modalNames } from 'src/shared/features/modal'
import type { TSliderItem, TSliderText } from './types'

type Props = {
  images: TImages
  sliderItems: TSliderItem[]
  sliderText: TSliderText
}

export const HeroBlock = ({ images, sliderItems, sliderText }: Props) => {
  const { label, title, text } = sliderText

  return (
    <Hero
      background={images['hero-mobile']}
      title={title}
      adornment
      label={label}
      sliderItems={sliderItems}
    >
      <HBox />
      <Body>{text}</Body>
      <HBox />
      {/* <Button onClick={() => openModal(modalNames.contactUs)}>
        Связаться с нами
      </Button> */}
    </Hero>
  )
}
