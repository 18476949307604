import React from 'react'

import { Image } from 'src/ui/atoms'
import { breakpoints } from 'src/ui/dimensions'
import { Col, HBox, Wrapper } from 'src/ui/layout'
import { styled } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'

import { animated, interpolate, useSprings } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

const Title = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const StyledImage = styled(Image)`
  position: absolute !important;
  object-fit: contain;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;

  width: 100%;
  max-width: 1376px;
  height: 100%;

  z-index: 0;
  mix-blend-mode: multiply;

  @media (min-width: ${breakpoints.tablet}px) {
    transform: translateX(32px);
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;

  padding: 40px 0;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 84%;
    float: right;
  }
`
const GridWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white.primary};

  transition: all 0.3s;

  @media (max-width: ${breakpoints.tablet}px) {
    padding-left: 0;
  }
`

const StyledCol = styled(animated.div)`
  padding: 40px 24px 40px 40px;
  background: ${({ theme }) => theme.colors.white.primary};
  box-shadow: 0 0 0px 2px ${({ theme }) => theme.colors.white.primary};

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 24px;
  }
`

type Props = {
  images: TImages
}

const data = [
  {
    title: `Помогаем бизнесу и\u00A0людям`,
    description: `Наши сервисы приносят клиентам сотни миллионов рублей и\u00A0делают
  жизнь пользователей комфортнее. Мы\u00A0всегда концентрируемся на
  полезных свойствах продукта.`,
  },
  {
    title: `На нас можно положиться`,
    description: `KODE занимает первую\u00A0строчку среди российских компаний по мобильной
    разработке в\u00A0«Рейтинге\u00A0Рунета». Дизайнеры тоже не отстают и третий год заслуженно становятся лучшей командой дизайна мобильных приложений.`,
  },
  {
    title: `Стремительно развиваемся`,
    description: `Команда фокусируется на\u00A0развитии технологий и\u00A0сотрудников. У\u00A0нас нет начальников, только наставники и коучи. В каждом отделе
    работает программа развития — мы называем это технологизацией.`,
  },
  {
    title: `Делимся знаниями`,
    description: `Мы разрабатываем программы стажировок, выступаем на\u00A0конференциях
    и\u00A0посещаем знаковые события в IT-сфере. KODE помогает сотрудникам вести профессиональные
    блоги и\u00A0выступать перед IT сообществом. Так мы передаём опыт, развиваем soft skills и\u00A0эмоциональный интеллект.`,
  },
  {
    title: `Гибко мыслим`,
    description: `Мы прислушиваемся к\u00A0амбициям и\u00A0мнению каждого человека. Известны
    случаи, когда ради совершенства продукта способные сотрудники\u00A0создавали новые подразделения компании.`,
  },
]

export const WhyWe: React.FC<Props> = ({ images }) => {
  const to = (i: number) => ({
    x: 0,
    y: 0,
    scale: 1,

    opacity: 1,
    delay: i * 100,
  })
  const from = (i: number) => ({
    x: 300,
    y: 100,
    opacity: 0,
    scale: 1.5,
  })

  const [props, set] = useSprings(data.length, (i) => ({
    from: from(i),
  }))

  const handler = (active: boolean) => {
    if (active) {
      // @ts-ignore
      set((i) => {
        return to(i)
      })
    }
  }

  return (
    <>
      <Wrapper>
        <Title>
          <Col>
            <Label weight="bold">ПОЧЕМУ KODE</Label>
            <HBox height={8} />
            <Header size="h2" as="h2">
              Лучшие люди делают лучшую работу
            </Header>
          </Col>

          <Col>
            <HBox height={24} />
            <Body>
              KODE разрабатывает мобильные приложения с 2013 года. За это время
              штат сотрудников вырос с двух&nbsp;до&nbsp;350 человек в
              трех&nbsp;странах и шести&nbsp;городах — только
              в&nbsp;калининградском офисе работает больше 150&nbsp;человек.
              <br />
              <br />
              В&nbsp;портфеле проектов KODE более 50&nbsp;известных брендов, в
              том числе авиакомпании Utair и «Победа», банки «Открытие» и ВТБ,
              медицинские сервисы «Доктор Рядом» и «НаПоправку», сеть заведений
              быстрого питания Burger King и сеть магазинов Spar.
              <br />
              <br />
              Команда развивает и новые направления в&nbsp;digital:
              разрабатывает разговорные продукты и&nbsp;растит
              VoiceTech-сообщество.
            </Body>
          </Col>
        </Title>
      </Wrapper>

      <HBox height={64} />

      <VisibilitySensor onChange={handler} partialVisibility>
        <GridWrapper>
          <StyledImage fluid={images['why-kode']} objectPosition="top left" />
          <Wrapper>
            <Grid>
              <div></div>

              {
                //@ts-ignore
                props.map(({ x, y, opacity, scale }, i) => (
                  <StyledCol
                    key={i}
                    style={{
                      transform: interpolate(
                        [x, y, scale],
                        (x, y, scale) =>
                          `translate3d(${x}px,${y}px,0) scale(${scale})`
                      ),
                      opacity: opacity,
                    }}
                  >
                    <Header size="h4" as="h3">
                      {data[i].title}
                    </Header>
                    <HBox />
                    <Body> {data[i].description}</Body>
                  </StyledCol>
                ))
              }
            </Grid>
          </Wrapper>
        </GridWrapper>
      </VisibilitySensor>
    </>
  )
}
