import React from 'react'

import { Link } from 'gatsby'
import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { HBox, Wrapper } from 'src/ui/layout'
import { Hero, SwiperSlider } from 'src/ui/organisms'
import { Body, Header, Label } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Cities = ({ images }: Props) => (
  <>
    <Wrapper>
      <Label weight="bold">ОФИСЫ KODE</Label>
      <HBox height={8} />
      <Header size="h4" as="h4">
        Города кодеров
      </Header>
      <HBox height={8} />
      <Body size="secondary">Мы работаем там, где комфортно жить.</Body>
      <HBox />
    </Wrapper>
    <SwiperSlider>
      <SwiperSlide>
        <Hero
          background={images['kaliningrad']}
          titleAs="h2"
          title="Калининград"
          textHeight={237}
          titleSize="h4"
          textWidth={86}
        >
          <HBox height={8} />
          <Body size="secondary">
            Европейские ценности, море и&nbsp;мягкий климат
          </Body>
          <HBox />
          <Body>
            <Link to="/kaliningrad/">Подробнее&nbsp;→</Link>
          </Body>
        </Hero>
        <HBox height={48} />
        <Hero
          background={images['saint-petersburg']}
          titleAs="h2"
          title="Санкт-Петербург"
          textHeight={237}
          titleSize="h4"
          textWidth={86}
        >
          <HBox height={8} />
          <Body size="secondary">
            Интеллектуальная среда, культурное наследие
          </Body>
          <HBox />
          <Body>
            <Link to="/saint-petersburg/">Подробнее&nbsp;→</Link>
          </Body>
        </Hero>
      </SwiperSlide>
    </SwiperSlider>
  </>
)
