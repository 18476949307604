import React from 'react'
import { styled } from 'src/ui/theme'

export const useClippedCollection = <T extends unknown>(collection: T[]) => {
  const [clipped, setClipped] = React.useState(collection.map(() => true))

  const setClippedByIndex = (slideIndex: number) =>
    setClipped((prev) =>
      prev.map((item, index) => (index !== slideIndex ? item : false))
    )

  return {
    clipped,
    setClippedByIndex,
  }
}
