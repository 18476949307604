import { Link } from 'gatsby'
import React from 'react'

import { Image } from 'src/ui/atoms'
import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { HBox, Row, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'

const StyledRow = styled(Row)`
  display: grid;

  grid-template-columns: auto auto auto;
  gap: 48px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: auto;
  }
`

const StyledCol = styled.div`
  display: flex;
  flex-flow: column;
`

const StylesImage = styled(Image)`
  z-index: 0;
  mix-blend-mode: multiply;
`

const HoverTitle = styled.div`
  opacity: 0;
  position: absolute;

  left: 64px;
  bottom: 64px;
  max-width: 320px;
  padding: 0 16px;
  z-index: 2;

  transition: opacity 0.3s;
`

const HoverWrapper = styled.div`
  position: relative;
  height: 528px;
  background: ${({ theme }) => theme.colors.white.primary};

  transition: all 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.brand.primary};

    ${HoverTitle} {
      opacity: 1;
    }
  }
`

type Props = {
  images: TImages
}

const data = [
  {
    title: `Развлекаемся`,
    description: `Сотрудники KODE любят проводить время друг с\u00A0другом, поэтому регулярно встречаются за пределами офиса: ходят в\u00A0кино, на\u00A0концерты и лекции, посещают мастер-классы.`,
    link: `/fun`,
  },
  {
    title: `Занимаемся творчеством`,
    description: `Наша музыкальная кавер-группа «Ласковый\u00A0дедлайн» выступает на\u00A0корпоративах и\u00A0не\u00A0только.`,
    link: `/fun/#band`,
  },
  {
    title: `Путешествуем`,
    description: `Каждый сезон исследуем туристические маршруты региона, играем во
    фрисби на море и\u00A0два раза в\u00A0год наслаждаемся корпоративами в\u00A0Европе.`,
    link: `/fun/#travel`,
  },
]

export const Rest: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const { innerWidth } = useDimensions()

  return (
    <Wrapper>
      <Label weight="bold">ОТДЫХ</Label>
      <HBox height={24} />
      <Header size="h2" as="h2">
        Жизнь вне офиса
      </Header>
      <HBox height={72} />

      <StyledRow>
        {data.map((item, i) => (
          <StyledCol key={i}>
            <Header size="h4" as="h3">
              {item.title}
            </Header>
            <HBox />
            <Body>{item.description}</Body>
            <HBox height={8} />
            <Body>
              <Link to={item.link}>Подробнее →</Link>
            </Body>
          </StyledCol>
        ))}
      </StyledRow>

      <HBox height={64} />
      <HoverWrapper>
        <StylesImage
          objectPosition={
            innerWidth < breakpoints.desktop ? 'center center' : 'top left'
          }
          fluid={images['team']}
          height={528}
        />
        <HoverTitle>
          <Header size="h4" color={theme.colors.white.primary}>
            Команда KODE отмечает семилетие компании
          </Header>
        </HoverTitle>
      </HoverWrapper>
    </Wrapper>
  )
}
