import React, { ReactNode } from "react";
import { Icons } from "src/ui/icons";
import { styled } from "src/ui/theme"
import { Body } from "src/ui/typography";

type TProps = {
    iconVariant: TIconVariant;
    text: ReactNode;
}

type TIconVariant = 'ruward' | 'hh' | 'forbes'

const Wrapper = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    max-width: 421px;
    width: 100%;
    gap: 16px;
    text-align: center;
    background: ${({ theme }) => theme.colors.white.primary};
    text-decoration: none !important;
    color: inherit !important;
`

const getIcon = (iconVariant: TIconVariant) => {
    switch (iconVariant) {
        case 'ruward':
            return Icons.RuwardIcon();
        case "hh":
            return Icons.HhIcon();
        case "forbes":
            return Icons.ForbesIcon();
        default:
            return null;
    }
}

const getHref = (iconVariant: TIconVariant) => {
    switch (iconVariant) {
        case 'ruward':
            return 'https://ruward.ru/award/2024/84487/';
        case "hh":
            return "https://rating.hh.ru/history/rating2023/summary?tab=regular";
        case "forbes":
            return 'https://www.forbes.ru/ratings/best-employers';
        default:
            return '';
    }
}

export const AchievementCard = ({iconVariant, text}: TProps) => {
    return <Wrapper href={getHref(iconVariant)} target="_blank">
        {getIcon(iconVariant)}
        <Body weight='medium'>
            {text}
        </Body>
    </Wrapper>
}