import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { JobList as Desk } from './desk'
import { JobList as Mob } from './mob'

import { Props } from './types'

export const JobList = (props: Props) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob {...props} /> : <Desk {...props} />
}
