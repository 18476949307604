import React from "react";
import { styled } from "src/ui/theme";
import { AchievementCard } from "src/ui/molecules/achievement-card";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    padding: 48px 16px;
    background: ${({ theme }) => theme.colors.gray.headerBg};
    flex-wrap: wrap;
`;

export const Achievements = () => {
    return <Wrapper>
        <AchievementCard iconVariant='hh' text={<>3-е место среди лучших работодателей России в IT-компаниях среднего размера по версии hh.ru 2023</>}/>
        <AchievementCard iconVariant='forbes' text='топ лучших работодателей по версии Forbes 2023'/>
        <AchievementCard iconVariant='ruward' text='лучший HR-бренд среди разработчиков мобильных приложений по версии RUWARD 2024'/>
    </Wrapper>
}