import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const WhyWe: React.FC<Props> = ({ images }) => (
  <>
    <Hero
      background={images['why-kode-mobile']}
      titleAs="h2"
      title="Лучшие люди делают лучшую работу"
      label="ПОЧЕМУ KODE"
      textHeight={260}
    >
      <HBox />
      <Body>
        KODE разрабатывает мобильные приложения с 2013 года. За это время штат
        сотрудников вырос с двух&nbsp;до&nbsp;350 человек в трех&nbsp;странах и
        шести&nbsp;городах — только в&nbsp;калининградском офисе работает больше
        150&nbsp;человек.
        <br />
        <br />
        В&nbsp;портфеле проектов KODE более 50&nbsp;известных брендов, в том
        числе авиакомпании Utair и «Победа», банки «Открытие» и ВТБ, медицинские
        сервисы «Доктор Рядом» и «НаПоправку», сеть заведений быстрого питания
        Burger King и сеть магазинов Spar.
        <br />
        <br />
        Команда развивает и новые направления в&nbsp;digital: разрабатывает
        разговорные продукты и&nbsp;растит VoiceTech-сообщество.
      </Body>
    </Hero>
    <HBox height={24} />

    <Wrapper>
      <Header size="h5" as="h3">
        Помогаем бизнесу и людям
      </Header>
      <HBox height={8} />
      <Body>
        Наши сервисы приносят клиентам сотни миллионов рублей и&nbsp;делают
        жизнь пользователей комфортнее. Мы&nbsp;всегда концентрируемся на
        полезных свойствах продукта.
      </Body>
      <HBox height={24} />
      <Header size="h5" as="h3">
        На нас можно положиться
      </Header>
      <HBox height={8} />
      <Body>
        KODE занимает первую&nbsp;строчку среди российских компаний по мобильной
        разработке в&nbsp;«Рейтинге&nbsp;Рунета». Дизайнеры тоже не отстают и
        третий год заслуженно становятся лучшей командой дизайна мобильных
        приложений.
      </Body>
      <HBox height={24} />
      <Header size="h5" as="h3">
        Гибко мыслим
      </Header>
      <HBox height={8} />
      <Body>
        Мы прислушиваемся к&nbsp;амбициям и&nbsp;мнению каждого человека. В
        компании есть сотрудники, которые создали новые подразделения ради
        совершенствования продукта.
      </Body>
    </Wrapper>
  </>
)
