import React from 'react'

import { decOfNums } from 'src/shared/utils'
import { HBox, Wrapper, Row, Col } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Adornments, Divider } from 'src/ui/atoms'
import { Body, Header } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'

import { Props } from './types'
import { Link } from 'gatsby'

const JobsWrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main * 4}px 0;
  background-color: ${({ theme }) => theme.colors.gray.jobsBg};
`

const Title = styled(Col)`
  padding-top: ${({ theme }) => theme.paddings.main * 2}px;
  flex: 1;
  max-width: 1058px;
`

const Adornment = styled.div`
  position: relative;
`

const JobDescription = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DepartmentsList = styled.div`
  display: grid;

  grid-template-columns: repeat(3, minmax(276px, 1fr));
  grid-template-rows: auto;
  row-gap: 24px;
  column-gap: 48px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Department = styled.div`
  width: 100%;
  overflow: hidden;
`

type TRegion = {
  name: string
  count: number
}

export const JobList: React.FC<Props> = ({ byDepartmentsList, hotList }) => {
  const getVacancyCountByRegions = (regions: TRegion[]) =>
    regions.reduce((acc, cur) => acc + cur.count, 0)

  return (
    <JobsWrapper>
      <Wrapper>
        <Row style={{ justifyContent: 'space-between', position: 'relative' }}>
          <Title>
            <Header size="h2" as="h2">
              Команды KODE
            </Header>
            <HBox />
            <Body>
              Отделы компании чётко структурированы. В&nbsp;каждом работают
              тимлид, техлид, ведущие специалисты, начинающие сотрудники
              и&nbsp;их&nbsp;менторы. Отделы постоянно сотрудничают
              друг&nbsp;с&nbsp;другом, обмениваются&nbsp;информацией, идеями
              и&nbsp;опытом их&nbsp;воплощения.
            </Body>
          </Title>
          <Adornment>
            <Adornments.Human />
          </Adornment>
        </Row>

        <HBox height={48} />
        <Divider />
        <HBox height={48} />

        {Boolean(byDepartmentsList) && (
          <DepartmentsList>
            {byDepartmentsList.map((department, index) => {
              const activeVacancies = getVacancyCountByRegions(
                department.regions
              )

              return (
                <Department key={index}>
                  <Header size="h4" as="h3">
                    {department.departmentName}
                  </Header>
                  <HBox height={8} />
                  <JobDescription size="primary">
                    {department.description}
                  </JobDescription>
                  <HBox height={8} />
                  <Body size="primary" weight="medium">
                    <Link
                      to="/career/"
                      state={{
                        department: {
                          label: department.departmentName,
                          value: department.departmentId,
                        },
                      }}
                    >
                      {`${activeVacancies} ${decOfNums.formatVacancy(
                        activeVacancies
                      )}`}
                    </Link>
                  </Body>
                </Department>
              )
            })}
          </DepartmentsList>
        )}
      </Wrapper>
    </JobsWrapper>
  )
}
