import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { Col, HBox, Row, VBox, Wrapper } from 'src/ui/layout'
import { styled } from 'src/ui/theme'

import { Link } from 'gatsby'
import { Adornments, Image } from 'src/ui/atoms'
import { Body, Header, Label } from 'src/ui/typography'

import { animated, useSpring } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

const StyledRow = styled(Row)`
  margin-top: 88px;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-flow: column;
    margin-top: 24px;
  }
`

const StyledCol = styled(Col)`
  width: 50%;
  max-height: 573px;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

const Control = styled(Link)`
  padding: 32px 40px 32px 0;
  position: relative;
  bottom: -24px;
  width: 80%;
  z-index: 1;
  text-decoration: none;
  transition: all 0.3s;
  background: ${({ theme }) => theme.colors.white.primary};
  p,
  a,
  div {
    transition: color 0.3s;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.brand.primary};
    padding-left: 40px;
    p,
    a,
    div {
      color: #fff;
    }
  }
`

const UnlinkedControl = styled.div`
  padding: 32px 40px 32px 0;
  position: relative;
  bottom: -24px;
  width: 80%;
  z-index: 1;
  text-decoration: none;
  transition: all 0.3s;
  background: ${({ theme }) => theme.colors.white.primary};
`

const Adornment1 = styled.div`
  position: absolute;
  right: -43px;
  top: 16px;
  transform: rotateZ(-15deg);
`

const Adornment2 = styled.div`
  position: absolute;
  right: 57px;
  bottom: -43px;
  transform: rotateZ(-15deg);
`

type Props = {
  images: TImages
}

export const Cities: React.FC<Props> = ({ images }) => {
  const [visible, setVisible] = React.useState(false)
  const handler = (active: boolean) => {
    if (active) {
      setVisible(true)
    }
  }

  const contentAnimation = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateY(0)' : 'translateY(120px)',
  })

  return (
    <VisibilitySensor onChange={handler} partialVisibility>
      <Wrapper>
        <Label weight="bold">ОФИСЫ KODE</Label>
        <HBox height={24} />
        <Header size="h2" as="h2">
          Города кодеров
        </Header>
        <HBox height={8} />
        <Body>Мы работаем там, где комфортно жить.</Body>
        <animated.div style={contentAnimation}>
          <StyledRow>
            <StyledCol>
              <Control to="/kaliningrad/">
                <Adornment1>
                  <Adornments.DashedSquare />
                </Adornment1>
                <Header size="h4">Калининград</Header>
                <HBox height={8} />
                <Body size="secondary">
                  Европейские ценности, море и мягкий климат
                </Body>
                <HBox />
                <Body>
                  <Link to="/kaliningrad/">Подробнее →</Link>
                </Body>
              </Control>
              <Image height={408} fluid={images['kaliningrad']} />
            </StyledCol>
            <VBox width={48} />
            <StyledCol>
              <Control to="/saint-petersburg/">
                <Adornment2>
                  <Adornments.DashedSquare />
                </Adornment2>
                <Header size="h4">Санкт-Петербург</Header>
                <HBox height={8} />
                <Body size="secondary">
                  Интеллектуальная среда, культурное <br /> наследие, активный
                  отдых
                </Body>
                <HBox />
                <Body>
                  <Link to="/saint-petersburg/">Подробнее →</Link>
                </Body>
              </Control>
              <Image height={408} fluid={images['saint-petersburg']} />
            </StyledCol>
          </StyledRow>
        </animated.div>
      </Wrapper>
    </VisibilitySensor>
  )
}
