import { Link } from 'gatsby'
import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Rest: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['team-mobile']}
    title="Жизнь вне офиса"
    adornment={true}
    label="ОТДЫХ"
    objectPosition="top left"
  >
    <HBox />
    <Header size="h5" as="h3">
      Развлекаемся
    </Header>
    <HBox height={8} />
    <Body>
      Сотрудники KODE любят проводить время друг с&nbsp;другом, поэтому
      регулярно встречаются за пределами офиса:&nbsp;ходят в&nbsp;кино, на на
      концерты и&nbsp;лекции, посещают мастер-классы.
    </Body>
    <HBox height={8} />
    <Body>
      <Link to="/fun/">Подробнее</Link>
    </Body>

    <HBox height={24} />

    <Header size="h5" as="h3">
      Занимаемся творчеством
    </Header>
    <HBox height={8} />
    <Body>
      Наша музыкальная кавер-группа «Ласковый&nbsp;дедлайн» выступает на
      корпоративах&nbsp;и не только.
    </Body>
    <HBox height={8} />
    <Body>
      <Link to="/fun/#band">Подробнее</Link>
    </Body>

    <HBox height={24} />

    <Header size="h5" as="h3">
      Путешествуем
    </Header>
    <HBox height={8} />
    <Body>
      Каждый сезон исследуем туристические маршруты региона, играем
      во&nbsp;фрисби на море и&nbsp;два раза в&nbsp;год наслаждаемся
      корпоративами в&nbsp;Европе.
    </Body>
    <HBox height={8} />
    <Body>
      <Link to="/fun/#travel">Подробнее</Link>
    </Body>
  </Hero>
)
